interface COLOR_DEF {
  [ key: string ]: {
    light: string,
    dark: string,
  };
}

/* CSS HSL */
/*
--rich-black-fogra-29: hsla(205, 100%, 7%, 1);
--ming: hsla(188, 68%, 25%, 1);
--blanched-almond: hsla(35, 100%, 91%, 1);
--amber-sae-ece: hsla(29, 100%, 50%, 1);
--kobe: hsla(15, 78%, 26%, 1);
 */

/*
--dark-spring-green: hsla(146, 43%, 30%, 1);
--middle-green: hsla(146, 32%, 44%, 1);
--light-yellow: hsla(60, 93%, 94%, 1);
--melon: hsla(14, 100%, 86%, 1);
--persian-orange: hsla(29, 64%, 55%, 1);
 */

/* CSS HSL */
/*
--nyanza: hsla(129, 53%, 90%, 1);
--turquoise-green: hsla(141, 45%, 81%, 1);
--turquoise-green-2: hsla(147, 43%, 71%, 1);
--ocean-green: hsla(150, 42%, 62%, 1);
--ocean-green-2: hsla(152, 41%, 52%, 1);
--illuminating-emerald: hsla(153, 39%, 41%, 1);
--bottle-green: hsla(153, 40%, 30%, 1);
--brunswick-green: hsla(155, 43%, 18%, 1);
--dark-jungle-green: hsla(159, 56%, 7%, 1);
 */


export const COLORS: COLOR_DEF = {
  primary: {
    light: 'hsl(147deg, 43%, 71%)',
    dark: 'hsl(230deg, 100%, 67%)',
  },
  secondary: {
    light: 'hsl(153deg, 40%, 30%)',
    dark: 'hsl(20deg, 100%, 67%)',
  },
  text: {
    light: 'hsl(159deg, 56%, 7)%',
    dark: 'hsl(0deg, 0%, 100%)',
  },
  background: {
    light: 'hsl(129deg, 53%, 90%)',
    dark: 'hsl(250deg, 70%, 7%)',
  },
  "background-secondary": {
    light: 'hsl(153deg, 40%, 30%)',
    dark: 'hsl(250deg, 70%, 7%)',
  },
  "background-card": {
    light: 'hsl(150deg, 42%, 62%)',
    dark: '',
  },
  "background-footer": {
    light: 'hsl(150deg, 42%, 62%)',
    dark: '',
  },
  'gray-100': {
    light: 'hsl(210deg, 17%, 98%)',
    dark: 'hsl(210deg, 15%, 20%)',
  },
  'gray-200': {
    light: 'hsl(210deg, 16%, 93%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
  'gray-300': {
    light: 'hsl(210deg, 14%, 89%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
  'gray-400': {
    light: 'hsl(210deg, 14%, 83%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
  'gray-500': {
    light: 'hsl(210deg, 11%, 71%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
  'gray-600': {
    light: 'hsl(208deg, 7%, 46%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
  'gray-700': {
    light: 'hsl(210deg, 9%, 31%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
  'gray-800': {
    light: 'hsl(210deg, 10%, 23%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
  'gray-900': {
    light: 'hsl(210deg, 11%, 15%)',
    dark: 'hsl(210deg, 15%, 25%)',
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
