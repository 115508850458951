import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  // Global styles
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    line-height: 1.5;
    line-height: calc(1em + 0.625rem);
    -webkit-font-smoothing: antialiased;
  }

  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  ul {
    padding: 0;
  }

  #___gatsby {
    isolation: isolate;
  }

  body {
    background: var(--color-background);
    color: var(--color-text);
    font-family: sans-serif;
  }

  // CSS variables
  :root {
    --font-weight-bold: 600;
    --font-weight-medium: 500;
    --font-weight-light: 400;

    --header-height: 60px;
  }


`;

export default GlobalStyles;
