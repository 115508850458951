import React, { ReactNode } from "react";
import { COLOR_MODE_KEY, COLORS, INITIAL_COLOR_MODE_CSS_PROP } from "../constants";

interface Props {
  children: ReactNode;
}

export enum ThemeSetting {
  LIGHT = "light",
  DARK = "dark",
}

interface ThemeManager {
  colorMode: string | undefined;
  setColorMode: ( setting: ThemeSetting ) => void;
}

const defaultState: ThemeManager = {
  colorMode: ThemeSetting.LIGHT,
  setColorMode: () => undefined,
};

export const ThemeContext = React.createContext( defaultState );

export const ThemeProvider = ( { children }: Props ) => {
  const [colorMode, rawSetColorMode] = React.useState<string | undefined>( ThemeSetting.LIGHT );

  React.useEffect( () => {
    const root = window.document.documentElement;
    const initialColorValue = root.style.getPropertyValue( INITIAL_COLOR_MODE_CSS_PROP );

    rawSetColorMode( initialColorValue );
  }, [] );

  const contextValue = React.useMemo( () => {
    function setColorMode( newValue: ThemeSetting ) {
      const root = window.document.documentElement;

      localStorage.setItem( COLOR_MODE_KEY, newValue );

      Object.entries( COLORS ).forEach( ( [name, colorByTheme] ) => {
        const cssVarName = `--color-${name}`;

        root.style.setProperty( cssVarName, colorByTheme[ newValue ] );
      } );

      rawSetColorMode( newValue );
    }

    return {
      colorMode,
      setColorMode,
    };
  }, [colorMode, rawSetColorMode] );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
