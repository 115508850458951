import * as React from "react"
import { ReactNode } from "react";
import { ThemeProvider } from "./ThemeContext";
import GlobalStyles from "./GlobalStyles/GlobalStyles";

interface Props {
  children: ReactNode;
}

export default ( { children }: Props ) => (
  <ThemeProvider>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);
